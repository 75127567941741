.cookie-bar {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 60px;
  background-color: rgba($main-color, 1);
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  @include respond-to('medium') {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba($main-color, 0.5);
  }

  display: none;

  &.active {
    display: flex;
  }
}

.cookie-bar__text {
  margin-bottom: 20px;
  text-align: center;

  @include respond-to('medium') {
    text-align: left;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.cookie-bar__button {
  display: block;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 11px;
  padding: 3px 15px;
  text-transform: uppercase;
  font-family: $font-family;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 5px;

    @include respond-to('medium') {
      margin-bottom: 0;
      margin-right: 5px;
    }
  }
}
