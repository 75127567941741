.slide-in {
  // Attive solo in mobile
  @include respond-to('max-small') {
    opacity: 0;
    transition: all 600ms ease;

    &.from-left {
      transform: translateX(-30%);

      &::before {
        transform: translateX(20%);
      }

      img {
        transform: translateX(50%);
      }
    }

    &.from-right {
      transform: translateX(30%);

      &::before {
        transform: translateX(-20%);
      }

      img {
        transform: translateX(-50%);
      }
    }

    &.from-top {
      transform: translateY(-50%) rotate(-2deg);

      &::before {
        transform: translateY(20%);
      }

      img {
        transform: translateY(50%);
      }
    }

    &.from-bottom {
      transform: translateY(50%) rotate(2deg);

      &::before {
        transform: translateY(-20%);
      }

      img {
        transform: translateY(-50%);
      }
    }

    &.visible {
      opacity: 1;
      transform: none;
    }
  }

  // Attive ovunque
  @include respond-to('max-large') {
    &:not(.slide-in--mobile-only) {
      opacity: 0;
      transition: all 600ms ease;

      &.from-left {
        transform: translateX(-30%);

        &::before {
          transform: translateX(20%);
        }

        img {
          transform: translateX(50%);
        }
      }

      &.from-right {
        transform: translateX(30%);

        &::before {
          transform: translateX(-20%);
        }

        img {
          transform: translateX(-50%);
        }
      }

      &.from-top {
        transform: translateY(-50%) rotate(-2deg);

        &::before {
          transform: translateY(20%);
        }

        img {
          transform: translateY(50%);
        }
      }

      &.from-bottom {
        transform: translateY(50%) rotate(2deg);

        &::before {
          transform: translateY(-20%);
        }

        img {
          transform: translateY(-50%);
        }
      }

      &.visible {
        opacity: 1;
        transform: none;
      }
    }
  }

  &:not(.slide-in--no-desktop) {
    opacity: 0;
    transition: all 600ms ease;

    &.from-left {
      transform: translateX(-30%);

      &::before {
        transform: translateX(20%);
      }

      img {
        transform: translateX(50%);
      }
    }

    &.from-right {
      transform: translateX(30%);

      &::before {
        transform: translateX(-20%);
      }

      img {
        transform: translateX(-50%);
      }
    }

    &.from-top {
      transform: translateY(-50%) rotate(-2deg);

      &::before {
        transform: translateY(20%);
      }

      img {
        transform: translateY(50%);
      }
    }

    &.from-bottom {
      transform: translateY(50%) rotate(2deg);

      &::before {
        transform: translateY(-20%);
      }

      img {
        transform: translateY(-50%);
      }
    }

    &.visible {
      opacity: 1;
      transform: none;
    }
  }
}
