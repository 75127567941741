.storia {
  /*
  background-image: url('../img/storia-bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  */

  main {
    overflow-x: hidden;
    padding: 25vh $horizontal-padding 0;
    position: relative;
    margin-bottom: 100px;

    @include respond-to('medium') {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  overflow: hidden;

  header {
    will-change: transform;
    position: fixed;
    width: 100%;
  }

  @include respond-to('extra-large') {
    overflow: hidden;

    header {
      will-change: transform;
      position: fixed;
      width: 100%;
    }

    main {
      padding: 0;
      overflow-y: hidden;
      margin-bottom: 0;
    }
  }

  // No animazioni slide in in desktop
  /*
  @include respond-to('extra-large') {
    .slide-in {
      opacity: 1;
      transform: none;
    }
  }
  */
}

.storia-back-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: back-img-anim 1s linear 1;
  animation-fill-mode: forwards;

  img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}

@keyframes back-img-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.story-section {
  font-size: 25px;
  //margin-bottom: 110px;
  position: relative;

  $padding: 20px;

  padding: 12vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  opacity: 0.2;
  transition: opacity 500ms linear 500ms;

  &.active {
    opacity: 1;
  }

  div {
    text-align: left;

    &.right {
      text-align: right;

      ol {
        direction: rtl;
        padding-left: 0;
        padding-right: 1.7em;
      }
    }

    strong {
      display: block;
      font-size: 2rem;
      margin-bottom: 10px;
    }

    ol {
      display: block;
      list-style: decimal-leading-zero;
      font-size: 1rem;
      padding-left: 1.7em;
      line-height: 1.2rem;
    }
  }

  @include respond-to('extra-large') {
    height: auto;
    font-size: 30px;
    margin-bottom: 0;
    //margin-bottom: 200px;

    padding-top: 40vh;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    opacity: 0;

    div {
      padding-left: $padding;
      border-left: 1px solid #ffffff;
      text-align: left;
      max-width: 600px;

      &.right {
        text-align: right;
        padding-left: 0;
        padding-right: $padding;
        border-left: none;
        border-right: 1px solid #ffffff;

        ol {
          direction: rtl;
          padding-left: 0;
          padding-right: 1.7em;
        }
      }

      strong {
        font-size: 3rem;
      }
    }
  }
}

$first-section-padding: 40vh;
.story-section-1 {
  /*
  & > div {
    transform: translateY(0);
  }*/
  @include respond-to('extra-large') {
    padding-top: $first-section-padding;

    &.es {
      padding-left: 100px;
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-2 {
  /*
  & > div {
    transform: translateY(0);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(270px - 40vh);
    }

    &.en {
      margin-top: calc(300px - 40vh);
    }

    &.es {
      margin-top: calc(220px - 40vh);
    }

    &.fr {
      margin-top: calc(250px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-3 {
  /*
  & > div {
    transform: translateY(-30%);
  }*/

  @include respond-to('extra-large') {
    &.it {
      padding-left: 100px;
      margin-top: calc(220px - 40vh);
    }

    &.en {
      padding-left: 100px;
      margin-top: calc(220px - 40vh);
    }

    &.es {
      margin-top: calc(210px - 40vh);
      padding-left: 100px;
    }

    &.fr {
      margin-top: calc(180px - 40vh);
      padding-left: 100px;
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-4 {
  /*
  & > div {
    transform: translateY(-90%);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(310px - 40vh);
    }

    &.en {
      margin-top: calc(280px - 40vh);
    }

    &.es {
      margin-top: calc(250px - 40vh);
    }

    &.fr {
      margin-top: calc(250px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-5 {
  /*
  & > div {
    transform: translateY(-75%);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(400px - 40vh);
    }

    &.en {
      margin-top: calc(370px - 40vh);
    }

    &.es {
      margin-top: calc(370px - 40vh);
    }

    &.fr {
      margin-top: calc(370px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-6 {
  /*
  & > div {
    transform: translateY(-50%);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(430px - 40vh);
    }

    &.en {
      margin-top: calc(400px - 40vh);
    }

    &.es {
      margin-top: calc(410px - 40vh);
    }

    &.fr {
      margin-top: calc(430px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-7 {
  /*
  & > div {
    transform: translateY(-50%);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(350px - 40vh);
    }

    &.en {
      margin-top: calc(360px - 40vh);
    }

    &.es {
      margin-top: calc(350px - 40vh);
    }

    &.fr {
      margin-top: calc(350px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-8 {
  /*
  & > div {
    transform: translateY(-40%);
  }*/

  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(410px - 40vh);
    }

    &.en {
      margin-top: calc(410px - 40vh);
    }

    &.es {
      margin-top: calc(410px - 40vh);
    }

    &.fr {
      margin-top: calc(410px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-9 {
  /*
  & > div {
    transform: translateY(-20%);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(400px - 40vh);
    }

    &.en {
      margin-top: calc(410px - 40vh);
    }

    &.es {
      margin-top: calc(400px - 40vh);
    }

    &.fr {
      margin-top: calc(370px - 40vh);
    }

    & > div {
      transform: none;
    }
  }
}

.story-section-10 {
  /*
  & > div {
    transform: translateY(0);
  }
*/
  @include respond-to('extra-large') {
    &.it {
      margin-top: calc(450px - 40vh);
      margin-bottom: 300px;
    }

    &.en {
      margin-top: calc(450px - 40vh);
      margin-bottom: 300px;
    }

    &.es {
      margin-top: calc(400px - 40vh);
      margin-bottom: 300px;
    }

    &.fr {
      margin-top: calc(400px - 40vh);
      margin-bottom: 300px;
    }

    & > div {
      transform: none;
    }
  }
}

.story-navigator {
  will-change: transform;
  position: fixed;
  bottom: 50px;
  left: 50px;

  ul {
    list-style-type: none;

    li {
      display: block;
      padding-left: 20px;
      position: relative;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 1.5px solid #ffffff;
        transform: translateY(-50%);
        transition: all 150ms linear;
      }

      &.active {
        &::before {
          background-color: #ffffff;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        &::after {
          display: block;
          content: '';
          position: absolute;
          top: calc(50% + 4px);
          right: calc(100% + 4px);
          height: 26px;
          width: 1.5px;
          background-color: #ffffff;
        }
      }
    }
  }

  a {
    display: block;
    color: #ffffff;
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
  }
}

#path-anim-1,
#path-anim-2,
#path-anim-3,
#path-anim-4 {
  position: absolute;
  left: 50%;
  top: calc(#{$first-section-padding} - 230px); //MAGIC NUMBER
  transform: translateX(-50%);
  width: 1300px; //MAGIC NUMBER
  height: auto;
  z-index: $z-index-back-anims;

  svg > g > g {
    will-change: transform;
  }
}

#path-anim-1 {
  filter: blur(7px);
}

#path-anim-2 {
  opacity: 0.8;
}

#path-anim-3 {
  filter: blur(20px);
}

#path-anim-4 {
  filter: blur(4px);
}
