// body
.sistemiDiDistribuzione {
  main {
    @include standard-dimensions;

    position: relative;
    //padding: 60px 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;

    .standard-title {
      margin-top: 20px;
    }

    .page-number {
      // Status iniziale animazione
      visibility: hidden;
      transform: translateY(-40%);
      will-change: transform;
    }

    @include respond-to('medium') {
      .page-number {
        transform: none;
      }
    }

    @include respond-to('large') {
      //padding: 50px 100px;
      //max-width: 1900px;
      //margin: 0 auto;
      //overflow-x: initial;

      //overflow-y: hidden;

      .standard-title {
        margin-top: 50px;
      }

      .page-number {
        transform: none;
        right: initial;
        left: 0;
      }
    }

    @include respond-to('huge') {
      //padding: 200px 100px;
    }
  }

  // Status iniziale animazione
  h1 {
    visibility: hidden;
  }

  .sections-container {
    @include respond-to('large') {
      margin-left: 0;
      margin-right: auto;
      flex-direction: row;
    }
  }

  .sections {
    margin-left: 0;
    text-align: left;

    &::before {
      left: 6.5px;
      right: initial;
    }
  }

  .sections-slider {
    right: 0;
    left: initial;
    text-align: right;

    &::before {
      right: 6.5px;
      left: initial;
      height: 95%;
      top: 2px;
    }

    .section {
      padding-right: 30px;
      padding-left: 0;

      &::before {
        right: 0;
        left: initial;
      }
    }
  }

  .section {
    padding-left: 30px;
    padding-right: 0px;

    &--servizi-idrici-integrati {
      animation-delay: 4000ms;

      &::before {
        background-color: $servizi-idrici-integrati;
      }
    }

    &--reti-gas {
      animation-delay: 4100ms;

      &::before {
        background-color: $reti-gas;
      }
    }

    &::before {
      left: 0;
      right: initial;
    }
  }

  .slide {
    @include respond-to('large') {
      text-align: left;
    }
  }

  .slide__text {
    @include respond-to('large') {
      margin-left: 0;
    }
  }

  .slide__list {
    li {
      @include respond-to('large') {
        padding-left: 5px;
        padding-right: 0;
      }

      &::before {
        @include respond-to('large') {
          left: initial;
          right: 100%;
        }
      }
    }
  }

  .slide-button {
    @include respond-to('large') {
      margin-left: 100px;
      margin-top: 0;
    }
  }
}

#distribuzione-anim-1,
#distribuzione-anim-2 {
  position: absolute;
  z-index: $z-index-back-anims;
  height: 100vh;
  width: 150vh;
  right: -80%;
  top: -20%;
  transform: rotate(20deg);

  @include respond-to('medium') {
    height: auto;
    width: 100vw;
    left: 0;
    top: 0;
    right: initial;
    transform: none;
  }
}

#distribuzione-anim-1 {
  opacity: 0;
  transition: opacity 150ms linear;

  &.active {
    filter: blur(5px);
    opacity: 1;
  }
}

.distribuzione-text {
  width: 70%;
  margin-bottom: 70px;
  // Status iniziale animazione
  visibility: hidden;
  will-change: transform;

  @include respond-to('medium') {
    width: initial;
    margin-bottom: 50px;
    margin-right: auto;
    max-width: 700px;
  }
}
