// ============================================================================
// Colors
// ============================================================================

// COLORS

$main-color: rgb(8, 100, 152);

$text-color-base: rgb(255, 255, 255);

// Idroelettrico
$fornitura-impianti: rgb(255, 255, 255);
$interventi-e-manutenzione: rgb(0, 181, 204);
$controllo-e-gestione: rgb(96, 204, 121);

// Sistemi di distribuzione
$servizi-idrici-integrati: rgb(225, 225, 225);
$reti-gas: rgb(0, 88, 137);

// Turbine
$crossflow: rgb(234, 162, 0);
$pelton: rgb(96, 196, 121);
$kaplan: rgb(163, 201, 13);
$francis: rgb(48, 104, 160);

// END COLORS

// ============================================================================
// Breakpoints
// ============================================================================

$breakpoints: (
  'small': (
    // Extra small devices (portrait phones, greater than 576px)
      min-width: 576px
  ),
  'medium': (
    // Small devices (landscape phones, greater than 768px)
      min-width: 768px
  ),
  'large': (
    // Medium devices (tablets, greater than 992px)
      min-width: 992px
  ),
  'extra-large': (
    // Large devices (desktops, greater than 1200px)
      min-width: 1200px
  ),
  'huge': (
    // Huge devices (huge desktop, greater than 1600px)
      min-width: 1600px
  ),
  'max-small': (
    // Extra small devices (portrait phones, less than 576px)
      max-width: 768px
  ),
  'max-medium': (
    // Small devices (landscape phones, less than 768px)
      max-width: 992px
  ),
  'max-large': (
    // Medium devices (tablets, less than 992px)
      max-width: 1200px
  ),
  'max-extra-large': (
    // Large devices (desktops, less than 1200px)
      max-width: 1600px
  ),
  'landscape': (
    // Landscape oriented
      orientation: landscape
  ),
  'portrait': (
    // Potrat oriented
      orientation: portrait
  )
);

// Huge desktop: no max breakpoint required

// @media (max-width: $breakpoint-mobile) {...}

// ============================================================================
// Fonts
// ============================================================================

$font-family: 'Montserrat', 'Arial', sans-serif;
$font-size-base: 15px;

$weight-extra-bold: 800;
$weight-bold: 700;
$weight-medium: 500;
$weight-regular: 400;
$weight-light: 300;

// ============================================================================
// Margins & Paddings
// ============================================================================

$vertical-padding: 60px;
$horizontal-padding: 30px;
$medium-vertical-padding: 30px;
$medium-horizontal-padding: 50px;
$extra-large-vertical-padding: 30px;
$extra-large-horizontal-padding: 100px;
$huge-vertical-padding: 100px;
$huge-horizontal-padding: 100px;

// ============================================================================
// Widths & Heights
// ============================================================================

$standard-max-width: 1900px;

// ============================================================================
// Z indexes
// ============================================================================

$z-index-background: -1;
$z-index-back-anims: -1;
$z-index-middle: 5;
$z-index-menu: 10;
$z-index-header: 11;
$z-index-lightbox: 12;
$z-index-cookie-bar: 13;

// ============================================================================
// Others
// ============================================================================
