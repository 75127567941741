// body
.idroelettrico {
  main {
    @include standard-dimensions;

    position: relative;
    //padding: 60px 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;

    .standard-title {
      margin-top: 20px;
    }

    .page-number {
      // Status iniziale animazione
      visibility: hidden;
      will-change: transform;
    }

    @include respond-to('medium') {
      padding-top: 80px;
    }

    @include respond-to('large') {
      //padding: 50px 100px;
      //max-width: 1900px;
      // margin: 0 auto;
      // overflow-x: initial;

      //overflow-y: hidden;
      text-align: right;

      .standard-title {
        margin-top: 50px;
      }

      .page-number {
        left: initial;
        right: 0;
      }
    }

    @include respond-to('huge') {
      // padding: 200px 100px;
    }
  }

  // Status iniziale animazione
  h1 {
    visibility: hidden;
    will-change: transform;
  }
}

.back-anim-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;

  @include respond-to('large') {
    overflow-x: initial;
  }
}

#idroelettrico-anim-1,
#idroelettrico-anim-2 {
  position: absolute;
  z-index: $z-index-back-anims;
  height: auto;
  width: 100vh;
  top: 20%;
  left: -10%;
  transform: rotate(90deg) scaleY(1);

  @include respond-to('medium') {
    left: 30%;
  }

  @include respond-to('large') {
    height: auto;
    width: 120vw;
    left: -10vw;
    top: 30%;
    transform: none;
  }

  @include respond-to('extra-large') {
    top: 20%;
  }
}

#idroelettrico-anim-1 {
  opacity: 0;
  transition: opacity 150ms linear;

  &.active {
    filter: blur(5px);
    opacity: 1;
  }
}

.idroelettrico-text {
  width: 70%;
  margin-bottom: 70px;
  // Status iniziale animazione
  visibility: hidden;
  will-change: transform;

  @include respond-to('large') {
    width: initial;
    margin-bottom: 50px;
    margin-left: auto;
    max-width: 700px;
  }
}

.back-text {
  position: absolute;
  top: -70px;
  right: 230px;
  font-weight: 800;
  font-size: 20rem;
  opacity: 0.1;
}

.title {
  font-weight: 800;
  font-size: 6rem;
  display: block;
}

.subtitle {
  display: block;
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 30px;
  // Status iniziale animazione
  visibility: hidden;
}

.text {
  font-size: 0.82rem;
  line-height: 1.5rem;
  width: 50%;
  margin-left: auto;
  margin-bottom: 30px;
  transform-origin: top right;
}

.sections-container {
  @include respond-to('large') {
    margin-left: auto;
    max-width: 700px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

#sections-main {
  display: none;

  @include respond-to('large') {
    display: block;
    margin-left: 0;
  }
}

.sections {
  position: relative;
  display: block;
  margin-left: auto;
  visibility: hidden;
  text-align: right;
  width: max-content;

  @include respond-to('huge') {
    //margin-bottom: 50px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 6.5px;
    height: 100%;
    width: 2px;
    background-color: #ffffff;
  }
}

.sections-slider {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: left;
  visibility: visible;
  z-index: 6;

  &::before {
    left: 6.5px;
    right: initial;
  }

  .section {
    padding-left: 30px;
    padding-right: 0;
    font-size: 1rem;

    &::before {
      left: 0;
      right: initial;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.section {
  position: relative;
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 250ms ease;
  // Status iniziale animazione
  font-weight: $weight-bold;
  padding-right: 30px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &--controllo-e-gestione {
    animation-delay: 4000ms;

    &::before {
      background-color: $controllo-e-gestione;
    }
  }

  &--interventi-e-manutenzione {
    animation-delay: 4100ms;

    &::before {
      background-color: $interventi-e-manutenzione;
    }
  }

  &--fornitura-impianti {
    animation-delay: 4200ms;

    &::before {
      background-color: $fornitura-impianti;
    }
  }

  &::before {
    position: absolute;
    right: 0;
    top: calc(50% - 7.5px);
    display: block;
    content: '';
    width: 15px;
    height: 15px;
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
}

.section__submenu {
  display: block;
  color: #ffffff;
  text-decoration: none;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.navigator {
  display: none;

  position: absolute;
  left: 15%;
  bottom: 20px;
  font-size: 0.9rem;

  ul {
    list-style-type: none;

    li {
      padding: 10px;
      padding-left: 20px;
      position: relative;
      cursor: pointer;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        border: 1.5px solid #ffffff;
        transform: translateY(-50%);
        transition: all 150ms linear;
      }

      &.active {
        &::before {
          background-color: #ffffff;
        }
      }

      &:not(:last-child) {
        &::after {
          display: block;
          content: '';
          position: absolute;
          top: calc(50% + 3.5px);
          right: calc(100% + 4px);
          height: 30px;
          width: 1.5px;
          background-color: #ffffff;
        }
      }
    }
  }
}

.slider {
  position: relative;
  ////height: 950px; // TEMP, MAGIC NUMBER
  transition: height 150ms ease;

  @include respond-to('medium') {
    ////height: 1100px; // TEMP, MAGIC NUMBER
  }

  @include respond-to('large') {
    //@include full-height;
    height: 100vh; // initial temp height
    margin-top: 200px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 1000ms ease-out 700ms, height 100ms ease;
  }

  @include respond-to('huge') {
    ////height: 900px; //MAGIC NUMBER
    margin-top: 400px;
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}

$desktop-slide-padding: 120px;
$huge-slide-padding: 220px;

.slide {
  position: absolute;
  padding-top: 20px;
  transition: transform 300ms ease;

  @include respond-to('large') {
    position: absolute;
    z-index: $z-index-middle;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: $desktop-slide-padding;

    pointer-events: none;
    transform: translateY(10%);
    opacity: 0;
    transition: all 500ms ease;

    text-align: right;

    height: 0;

    &.active {
      height: initial;
      transform: translateY(0);
      opacity: 1;
      pointer-events: initial;
    }
  }

  @include respond-to('huge') {
    padding-top: $huge-slide-padding;
    height: 750px; //MAGIC NUMBER
  }
}

#controllo-e-gestione {
  border-color: $controllo-e-gestione;

  .slide__topbar__circle {
    background-color: $controllo-e-gestione;
  }
}

#interventi-e-manutenzione {
  border-color: $interventi-e-manutenzione;

  .slide__topbar__circle {
    background-color: $interventi-e-manutenzione;
  }
}

#fornitura-impianti {
  border-color: $fornitura-impianti;

  .slide__topbar__circle {
    background-color: $fornitura-impianti;
  }
}

.slide__topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  @include respond-to('medium') {
    display: none;
  }
}

.slide__topbar__circle {
  width: 25px;
  height: 25px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.slide__topbar__title {
  font-weight: $weight-bold;
  margin-left: 15px;
  font-size: 1.2rem;
  width: max-content;
}

.slide__topbar__line {
  flex-grow: 1;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  border-radius: 1px;
  transform: translateX(12px);
}

.slide__submenu {
  margin-left: 65px;
  margin-bottom: 40px;

  @include respond-to('medium') {
    display: none;
  }

  li {
    font-weight: $weight-bold;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &::before {
      position: absolute;
      right: calc(100% + 15px);
      top: calc(50% - 7.5px);
      display: block;
      content: '';
      width: 15px;
      height: 15px;
      background-color: #319794;
      border: 2px solid #ffffff;
      border-radius: 50%;
    }
  }
}

.slide__submenu__button {
  color: #ffffff;
  text-decoration: none;
}

.slide__content {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms linear;

  @include respond-to('large') {
    padding-bottom: 100px;
  }

  @include respond-to('huge') {
    padding-bottom: 200px;
  }

  &.active {
    opacity: 1;
    position: initial;
    pointer-events: initial;
  }

  &:not(.slide__content-main) {
    .slide__image {
      display: none;
      /*
      @include respond-to('large') {
        display: block;
      }
      */
    }

    &#slide-content-turbine-vlh {
      padding-bottom: 30px;
    }

    .slide__image--mobile {
      display: block;

      @include respond-to('large') {
        display: none;
      }
    }
  }
}

.slide__title {
  font-weight: $weight-extra-bold;
  font-size: 1.7rem;
  display: block;
  margin-bottom: 10px;
  transition: transform 400ms ease;

  @include respond-to('large') {
    font-size: 5.1rem;
    margin-bottom: 20px;
  }
}

.slide__subtitle {
  display: none;
  font-weight: $weight-light;
  font-size: 1.5rem;
  margin-bottom: 30px;
  transition: transform 400ms ease;

  @include respond-to('large') {
    top: $desktop-slide-padding - 50px;
    left: 500px;
    position: absolute;
    font-size: 2.5rem;
    //transform: translateX(15%);
  }

  @include respond-to('huge') {
    top: $huge-slide-padding - 50px;
  }
}

.slide__text {
  transition: transform 400ms ease;

  @include respond-to('large') {
    font-size: 0.82rem;
    line-height: 1.5rem;
    width: 50%;
    margin-left: auto;
    margin-bottom: 30px;
    transform-origin: top right;
  }
}

.slide__list {
  //padding-left: 15px;
  //list-style: disc;
  margin-top: 40px;
  line-height: 1.5rem;

  @include respond-to('large') {
    font-size: 0.82rem;
  }

  li {
    padding-left: 5px;
    position: relative;

    @include respond-to('large') {
      padding-right: 5px;
      padding-left: 0;
    }

    &::before {
      content: '•';
      font-size: 1.5rem;
      position: absolute;
      right: 100%;
      top: 0;

      @include respond-to('large') {
        left: 100%;
        right: initial;
      }
    }
  }
}

.slide__image {
  display: block;
  margin-top: 50px;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.2);
    /*
    @include respond-to('large') {
      transform: none;
    }
    */
  }

  @include respond-to('large') {
    // New removed image
    display: none;

    /*
    position: fixed;
    z-index: -1;
    left: -$medium-horizontal-padding;
    bottom: -$medium-vertical-padding;
    height: 90vh;
    width: 100vw;
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%);

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
    }
    */
  }
  /*
  @include respond-to('extra-large') {
    left: -$extra-large-horizontal-padding;
    bottom: -$extra-large-vertical-padding;
  }

  @include respond-to('huge') {
    left: -$huge-horizontal-padding;
    bottom: -$huge-vertical-padding;
  }
  */
}

.slide-back-image {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 90vh;
  z-index: 0;
  opacity: 0;
  mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 70%,
    rgba(0, 0, 0, 1) 90%,
    rgba(0, 0, 0, 0)
  );
  transition: opacity 150ms linear;

  // Fix
  transform: translateY(10%);

  &.active {
    opacity: 1;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  /*
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  */
}

.slide__small-image-container {
  margin-bottom: 50px;
  margin-top: 50px;

  @include respond-to('large') {
    width: 50%;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
  }
}

.slide__small-image {
  height: 200px;
  display: block;

  &:first-child {
    margin-bottom: 20px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @include respond-to('large') {
    &:first-child {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.slide__table-container {
  margin-top: 50px;
  margin-bottom: 100px;

  @include respond-to('large') {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-bottom: 50px;
  }
}

.slide__table {
  width: 100%;

  &:first-child {
    margin-bottom: 50px;
  }

  @include respond-to('large') {
    padding: 0 20px;

    &:first-child {
      margin-bottom: 0px;
    }
  }
}

.slide__table__title {
  display: block;
  font-weight: $weight-bold;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}

.slide__table__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.slide-button {
  transition: transform 400ms ease;
  display: block;
  background: transparent;
  border: none;
  text-transform: uppercase;
  border: none;
  color: #fff;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;

  padding: 0.6em 1em;
  padding-right: 3em;
  border: 1.2px solid #ffffff;
  border-radius: 0.5rem;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }

  @include respond-to('medium') {
    margin-top: 100px;
  }

  @include respond-to('large') {
    margin-top: 0;
    margin-right: 100px;
    margin-bottom: 0;
  }
}

.slide-button {
  &::after {
    background-image: url('../img/svg/icons/gallery-button-icon.svg');
  }
}

.slider__arrow {
  display: none;
  height: 30px;
  position: absolute;
  bottom: 0px;
  cursor: pointer;

  &--prev {
    left: 0px;
    transform: scaleX(-1);
  }

  &--next {
    right: 0px;
  }

  &.active {
    display: block;
  }
}

.gallery {
  position: fixed;
  z-index: $z-index-lightbox;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms linear;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}

.gallery__slider {
  width: 100%;
  height: 100vh;
  position: relative;
}

.gallery__slider__slide {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 300ms ease;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.gallery__slider__arrow {
  position: absolute;
  top: 50%;
  height: 40px;
  width: 40px;
  padding: 7px;
  border-radius: 50%;
  transform: translateY(-50%);
  display: none;
  cursor: pointer;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);

  &--prev {
    left: 30px;
    transform: translateY(-50%) scaleX(-1);

    @include respond-to('large') {
      left: 60px;
    }
  }

  &--next {
    right: 30px;

    @include respond-to('large') {
      right: 60px;
    }
  }

  &.active {
    display: block;
  }
}

.gallery__close,
.case-study-container__close {
  //display: none; //TEMP?

  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  z-index: 3;
  cursor: pointer;

  // Aumenta area click
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  span {
    display: block;
    height: 2px;
    width: 130%; // MAGIC NUMBER, TEMP?
    background-color: #ffffff;
    transform-origin: center left;
    position: absolute;
    left: 0;

    &:first-child {
      top: 0;
      transform: rotate(45deg);
    }

    &:last-child {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
}

// Case study modal
.case-study-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $main-color;
  font-size: 20px;
  display: block;
  transform: translateY(-100%);
  pointer-events: none;
  transition: transform 350ms ease;

  &.active {
    transform: none;
    pointer-events: initial;
  }

  .case-study {
    display: none;

    @include standard-dimensions;

    &.visible {
      display: block;
    }

    h3 {
      font-size: 2.5rem;
      font-weight: $weight-bold;
      margin-top: 50px;
    }

    h4 {
      font-size: 1.5rem;
      margin-bottom: 50px;
    }

    p {
      font-size: 0.82rem;
      margin-bottom: 100px;
    }
  }

  .case-study__table-container {
    display: flex;
    flex-direction: row;
  }

  .case-study__table {
    max-width: 250px;
    width: 100%;
    overflow: hidden;

    &:not(:last-child) {
      @include respond-to('medium') {
        margin-right: 100px;
      }
    }
  }

  .case-study__table__title {
    display: block;
    width: min-content;
    font-weight: $weight-bold;
    margin-bottom: 20px;
    position: relative;

    &::after {
      display: block;
      content: '';
      background-color: #ffffff;
      height: 2px;
      width: 200px;
      position: absolute;
      left: calc(100% + 10px);
      top: 50%;
    }
  }

  .case-study__table__row {
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
