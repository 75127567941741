.politica {
  main {
    @include standard-dimensions;

    ul {
      list-style-type: disc;
      line-height: 1.5rem;
      margin-left: 20px;

      & > li > ul {
        list-style-type: circle;
      }
    }

    i {
      font-style: italic;
    }
  }
}
