.scroll-indicator {
  position: fixed;
  top: calc(50% - 5vh);
  right: 10px;
  height: 10vh;
  width: 2px;
  border-radius: 1px;
  background-color: #fff;
  opacity: 0.8;

  // Cerchietto indicatore
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #fff;
    animation: circle-animation 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 1;
  }
}

@keyframes circle-animation {
  0% {
    top: 50%;
  }
  100% {
    top: 100%;
  }
}
