.comingSoon {
  @include full-height;

  background: radial-gradient(
    farthest-corner at 100% 0%,
    #00a5b8 0%,
    #086496 70%
  );
  padding-top: 40px;

  @include respond-to('medium') {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    padding: 0;
  }
}

.coming-soon {
  &-logo {
    display: block;
    margin: 0 auto;
    width: 120px;

    @include respond-to('medium') {
      align-self: start;
      width: 150px;
      margin-top: 50px;
    }
  }

  &-title {
    display: block;
    font-weight: $weight-extra-bold;
    font-size: 2rem;
    user-select: none;
    text-align: center;
    position: absolute;
    top: calc(50% - 3rem);
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;

    @include respond-to('medium') {
      position: static;
      margin: 0;
      font-size: 3.5rem;
      transform: none;
    }
  }

  &-footer {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @include respond-to('medium') {
      position: static;
      align-self: end;
      margin-bottom: 50px;
      transform: none;
    }

    &-social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 15px;

      a {
        &:first-child {
          margin-right: 5px;
        }

        img {
          width: 30px;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: #ffffff;

        &:first-child {
          margin-right: 10px;
        }

        img {
          height: 1rem;
          margin-right: 5px;
        }
      }
    }
  }
}

.countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: $weight-extra-bold;
  font-size: 4rem;
  word-spacing: -1.2rem;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.2);
  user-select: none;

  small {
    font-size: 2rem;
  }

  @include respond-to('medium') {
    font-size: 13rem;

    small {
      font-size: 5rem;
    }

    br {
      display: none;
    }
  }
}
