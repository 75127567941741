.corporate {
  main {
    @include standard-dimensions;
  }

  table {
    margin-top: 4em;

    td,
    th {
      border: 1px solid #ffffff;
      padding: 1em;
      text-align: left;
      vertical-align: middle;
    }

    th {
      font-weight: $weight-bold;
    }

    a {
      color: #ffffff;

      span {
        @include respond-to('max-medium') {
          display: none;
        }
      }

      img {
        height: 2rem;

        @include respond-to('medium') {
          display: none;
        }
      }
    }
  }
}
