// body
.efficienzaIdroelettrico,
.efficienzaReti {
  @include respond-to('medium') {
    @include full-height;
    overflow: hidden;
  }

  main {
    @include standard-dimensions;
    @include respond-to('medium') {
      @include main-full-height;
    }
  }

  .divided-container {
    @include respond-to('medium') {
      display: grid;
      grid-template-columns: 4fr 3fr;
      height: 100%;
    }
  }

  .background-fixed {
    img {
      object-position: left center;

      @include respond-to('medium') {
        object-position: center center;
      }
    }
  }

  // Status iniziale animazione
  h1 {
    visibility: hidden;
    margin-bottom: 20px;
  }

  .efficienza-text {
    // Status iniziale animazione
    visibility: hidden;
    margin-bottom: 100px;

    @include respond-to('medium') {
      margin-right: auto;
      max-width: 700px;
      margin-bottom: 20px;
    }

    @include respond-to('huge') {
      margin-bottom: 50px;
    }
  }

  .right-section {
    position: relative;
    overflow: hidden;
    padding-top: 20px;

    // Status iniziale animazione
    visibility: hidden;

    @include respond-to('medium') {
      height: 100%;
      margin-left: 10vw;
      padding-top: 0;
      overflow: initial;
    }

    @include respond-to('huge') {
      margin-left: 12vw;
    }
  }

  .efficienza-card {
    cursor: pointer;
    position: relative;

    @include respond-to('medium') {
      position: absolute;
      top: 40%;

      opacity: 0.2;
      transition: opacity 500ms linear, transform 500ms ease;

      &.active {
        opacity: 1;

        .light-container {
          opacity: 1;
        }
      }

      &.hide {
        opacity: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: 50px;

      @include respond-to('medium') {
        margin-bottom: 100px;
      }
    }

    &-number {
      position: absolute;
      left: 0;
      top: -0.5em;
      font-size: 3rem;
      font-weight: $weight-extra-bold;
      opacity: 0.15;

      @include respond-to('medium') {
        left: -1em;
      }
    }

    h3 {
      font-weight: $weight-bold;
      font-size: 1.5rem;
    }

    ul {
      border-left: 1px solid #fff;
      margin-left: 1px; // To fix border
      margin-top: 3px;
      padding-left: 10px;
      padding-top: 5px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  .efficienza-navigator {
    will-change: transform;
    margin-left: 10px;

    // Status iniziale animazione
    visibility: hidden;

    ul {
      list-style-type: none;

      li {
        display: block;
        padding-left: 20px;
        position: relative;

        button {
          opacity: 0.5;
          transition: opacity 150ms linear;
        }

        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 50%;
          right: 100%;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          border: 1.5px solid #ffffff;
          transform: translateY(-50%);
          transition: all 150ms linear;
        }

        &.active {
          button {
            opacity: 1;
          }

          &::before {
            background-color: #ffffff;
          }
        }

        &:not(:last-child) {
          ////margin-bottom: 0px;

          &::after {
            display: block;
            content: '';
            position: absolute;
            top: calc(50% + 4px);
            right: calc(100% + 4px);
            height: 18px;
            width: 1.5px;
            background-color: #ffffff;
          }
        }
      }
    }

    button {
      display: block;
      background-color: transparent;
      border: none;
      color: #ffffff;
      text-decoration: none;
      padding: 5px;
      cursor: pointer;

      &:active,
      &:focus,
      &:hover {
        border: none;
        outline: none;
      }
    }
  }

  $circle-diameter: 90px;
  .light-container {
    position: absolute;
    left: -1em;
    top: -0.5em;
    height: $circle-diameter;
    width: $circle-diameter;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transform: translate(-53%, -42%);
    opacity: 0;
    transition: opacity 150ms linear;
  }

  $light-diameter: 25px;
  .light {
    position: absolute;
    top: calc(100% - #{$light-diameter / 2});
    left: calc(50% - #{$light-diameter / 2});
    width: $light-diameter;
    height: $light-diameter;
    border-radius: 50%;
    background-color: #ffffff;
    filter: blur(10px);
    transform-origin: $light-diameter / 2 $circle-diameter / -2 +
      $light-diameter / 2;
    animation: rotate 5s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
