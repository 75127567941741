.menu-container {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  @include full-height;
  width: 100vw;
  overflow-x: hidden;

  background-image: url('../img/menu-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include respond-to('medium') {
    background-image: url('../img/menu.jpg');
  }

  @include respond-to('huge') {
    background-image: url('../img/menu-huge.jpg');
  }

  @include respond-to('max-medium') {
    @include respond-to('landscape') {
      height: auto;
      //overflow: initial;
      position: absolute !important;
      overflow: visible !important;

      &.opened {
        clip-path: ellipse(200vmax 200vmax at 95% 5%) !important;
      }

      #menu-anim-1,
      #menu-anim-2 {
        display: none !important;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vw;
        height: 200vh;
        background-color: $main-color;
      }
    }
  }

  display: block;
  padding: 150px 70px 50px;
  box-sizing: border-box;
  clip-path: ellipse(
    0px 0px at calc(100% - 45px) 50px
  ); //PERCENTS: MAGIC NUMBERS
  pointer-events: none;
  opacity: 0;
  transition: clip-path 400ms ease, opacity 200ms linear 400ms;

  &.opened {
    position: fixed;
    clip-path: ellipse(120vmax 120vmax at 95% 5%);
    opacity: 1;
    pointer-events: initial;
    transition: clip-path 400ms ease, opacity 200ms linear 0ms;

    @include respond-to('medium') {
      #menu-anim-1,
      #menu-anim-2 {
        display: block;
      }
    }
  }

  @include respond-to('medium') {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    align-items: center;
    padding: 100px 50px 50px;
    clip-path: ellipse(
      0px 0px at calc(100% - 100px) 80px
    ); //PERCENTS: MAGIC NUMBERS;
  }

  @include respond-to('huge') {
    padding: 100px 250px;
  }
}

#menu-anim-1,
#menu-anim-2 {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  display: none;

  @include respond-to('medium') {
    transform: translate(-50%, -50%);
  }
}

#menu-anim-1 {
  filter: blur(23px);
  @include respond-to('medium') {
    opacity: 0.6;
  }
}

#menu-anim-2 {
  filter: blur(5px);
  @include respond-to('medium') {
    opacity: 0.3;
  }
}

.menu {
  grid-column: 2 / 3;

  nav > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 300;

    & > li {
      will-change: transform;
    }

    @include respond-to('medium') {
      font-size: 1.8rem;
    }

    & > li {
      position: relative;

      & > a {
        text-decoration: none;
        color: #ffffff;
        display: block;
        padding: 20px;
        padding-left: 0;
      }

      &:hover:not(.active) {
        .plus {
          opacity: 0.5;
        }
      }

      &.active {
        font-weight: 800;

        .plus {
          opacity: 1;

          span:first-child {
            height: 4px;
            top: calc(50% - 2px);
          }

          span:last-child {
            width: 4px;
            left: calc(50% - 2px);
          }
        }
      }
    }
  }
}

.has-submenu {
  cursor: pointer;

  a {
    pointer-events: none;
  }

  &.opened {
    .plus {
      opacity: 0.5;
    }
  }
}

.submenu {
  padding-left: 30px;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease-out;

  ul {
    margin-bottom: 0;
    font-size: 1.1rem;

    @include respond-to('medium') {
      font-size: 1.2rem;
    }

    li {
      a {
        display: block;
        color: #ffffff;
        text-decoration: none;
        padding: 5px;

        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        a {
          font-weight: $weight-bold;
        }
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
    max-height: 180px; // TEMP: MAGIC NUMBER
  }
}

.plus {
  height: 16px;
  width: 16px;
  position: absolute;
  left: -40px;
  top: calc(50% - 8px);
  opacity: 0;
  transition: all 250ms linear;

  span:first-child {
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    top: calc(50% - 1px);
  }

  span:last-child {
    width: 2px;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    left: calc(50% - 1px);
  }
}

.bottom-nav {
  border-top: 1px solid #ffffff;
  width: 380px; //MAGIC NUMBER
  padding-top: 20px;
  display: none;
  will-change: transform;

  div {
    will-change: transform;
  }

  @include respond-to('medium') {
    display: flex;

    // TEMP: mancano sezioni
    opacity: 0.5;
    pointer-events: none;
  }

  div {
    width: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      height: 40px;
      margin-right: 20px;
      transition: transform 250ms ease-in-out;
    }
  }
}

.info {
  align-self: end;
  font-size: 0.8rem;
  display: none;

  @include respond-to('medium') {
    display: block;
    margin-bottom: 20px;
  }
}

.info-box {
  will-change: transform;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  strong {
    display: block;
    margin-bottom: 10px;
  }

  span {
    display: block;
    font-weight: 300;
    margin-bottom: 10px;

    img {
      height: 10px;
      margin-right: 10px;
    }

    a {
      color: #ffffff;
      text-decoration: none;
    }
  }

  &--horizontal {
    display: none;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    right: 10px;

    @include respond-to('medium') {
      display: flex;
    }

    @include respond-to('max-medium') {
      @include respond-to('landscape') {
        display: none;
      }
    }
  }
}

.info-box__credit {
  font-size: 0.4rem;
  margin-right: 20px;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, auto);
  column-gap: 10px;
  color: #ffffff;
  text-decoration: none;

  img {
    grid-row: 1 / 3;
    height: 15px;
  }

  strong,
  span {
    margin-bottom: 0;
  }
}

.social-container {
  display: flex;
}

.social {
  height: 25px;

  &:first-child {
    margin-right: 10px;
  }
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  a {
    flex-basis: 23%;
    padding: 25px;

    // TEMP: mancano sezioni
    &:nth-child(3),
    &:nth-child(4) {
      opacity: 0.3;
      pointer-events: none;
    }

    &:not(:first-child) {
      transform: scale(0.9);
    }
  }
}

.menu-policy {
  position: absolute;
  bottom: 200px;
  right: -50px;
  transform: rotate(-90deg);
  font-size: 0.8rem;

  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;

    &:first-child {
      padding-right: 0.5em;
      margin-right: 0.5em;
      border-right: 1px rgba(255, 255, 255, 0.5) solid;
    }
  }
}
