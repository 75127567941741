.standard-button {
  transition: transform 400ms ease;
  display: block;
  width: max-content;
  background: transparent;
  border: none;
  text-transform: uppercase;
  border: none;
  color: #fff;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;

  padding: 0.6em 1em;
  border: 1.2px solid #ffffff;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;

  text-decoration: none;
}
