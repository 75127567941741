.storicoImpianti {
  background-image: url('../img/storico-impianti.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include respond-to('huge') {
    background-image: url('../img/storico-impianti-huge.jpg');
  }

  main {
    @include standard-dimensions;
    //padding: 30px 20px;

    h1 {
      margin-bottom: 50px;
    }

    @include respond-to('medium') {
      padding-left: 100px;
      padding-right: 100px;
    }

    @include respond-to('large') {
      @include respond-to('landscape') {
        padding-left: 50px;
        padding-right: 50px;
        //padding: 40px 100px;
        //max-width: 1900px;
        //margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        align-items: start;
        gap: 50px;

        h1 {
          grid-row: 1 / 2;
          //grid-column: 1 / 3;
          margin-bottom: 0;
          font-size: 4rem;
        }
      }
    }

    @include respond-to('extra-large') {
      padding-left: 150px;
      padding-right: 150px;

      h1 {
        font-size: 5rem;
      }
    }

    @include respond-to('huge') {
      padding-top: 5vh;
      padding-bottom: 5vh;
      //padding: 10vh 100px;
    }
  }
}

.graph-section {
  @include respond-to('large') {
    @include respond-to('landscape') {
      grid-row: 2 / 3;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.graph-section__percent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px 5px;
  font-weight: $weight-light;
  font-size: 1.1rem;
  margin-bottom: 50px;

  @include respond-to('large') {
    @include respond-to('landscape') {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      gap: 10px 0;
      margin-right: 10px;
    }
  }

  @include respond-to('extra-large') {
    display: block;
    margin-right: 20px;
    font-size: 1rem;
  }
}

.graph-section__percent__element {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;

  strong {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 8px solid;
  }
  @include respond-to('large') {
    @include respond-to('landscape') {
      span {
        position: absolute;
      }

      strong {
        margin-left: 2.7rem;
      }
    }
  }

  &.active {
    font-weight: $weight-bold;

    strong {
      //text-decoration: underline;
    }

    #cross-flow {
      border-color: rgba($crossflow, 1);
    }

    #pelton {
      border-color: rgba($pelton, 1);
    }

    #kaplan {
      border-color: rgba($kaplan, 1);
    }

    #francis {
      border-color: rgba($francis, 1);
    }
  }

  @include respond-to('large') {
    @include respond-to('landscape') {
      margin-bottom: 10px;
    }
  }
}

.graph-section__graph {
  display: block;
  width: 100%;
  margin: 0 auto 50px;
  max-width: 600px;

  @include respond-to('large') {
    @include respond-to('landscape') {
      max-width: none;
    }
  }
}

#cross-flow {
  border-color: rgba($crossflow, 0.6);
  transition: border-color 150ms linear;

  &:hover {
    border-color: rgba($crossflow, 0.8);
  }

  &.active {
    border-color: rgba($crossflow, 1);
  }
}

#pelton {
  border-color: rgba($pelton, 0.6);
  transition: border-color 150ms linear;

  &:hover {
    border-color: rgba($pelton, 0.8);
  }

  &.active {
    border-color: rgba($pelton, 1);
  }
}

#kaplan {
  border-color: rgba($kaplan, 0.6);
  transition: border-color 150ms linear;

  &:hover {
    border-color: rgba($kaplan, 0.8);
  }

  &.active {
    border-color: rgba($kaplan, 1);
  }
}

#francis {
  border-color: rgba($francis, 0.6);
  transition: border-color 150ms linear;

  &:hover {
    border-color: rgba($francis, 0.8);
  }

  &.active {
    border-color: rgba($francis, 1);
  }
}

.cross-flow-section {
  fill: $crossflow;
}

.pelton-section {
  fill: $pelton;
}

.kaplan-section {
  fill: $kaplan;
}

.francis-section {
  fill: $francis;
}

.graph-slice {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.7;
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0.8;
  }

  &.active {
    opacity: 1;
  }
}

.graph-section__graph__icon {
  fill: #ffffff;
}

.graph-section__graph__text {
  fill: #ffffff;
  font-size: 30px;
  font-family: $font-family;
}

.reference-info-section,
.reference-info-section-placeholder {
  @include respond-to('large') {
    @include respond-to('landscape') {
      grid-row: 1 / 3;
    }
  }
}

.reference-info-section {
  display: none;

  &.active {
    display: block;
  }
}

.reference-info-section-placeholder {
  display: none;
  text-align: center;

  &.active {
    display: grid;
    place-items: center;
    align-self: stretch;
  }

  @include respond-to('large') {
    text-align: left;
  }
}

.reference-info-section__name {
  display: block;
  text-align: center;
  font-weight: $weight-bold;
  font-size: 1.9rem;
  margin-bottom: 5px;

  @include respond-to('large') {
    @include respond-to('landscape') {
      text-align: left;
    }
  }
}

.reference-info-section__select {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // align-items: center;
  gap: 10px;
  margin-top: 10px;

  @include respond-to('extra-large') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.reference-info-section__select__button {
  display: block;
  background: transparent;
  border: none;
  text-transform: uppercase;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 0.8rem;
  font-weight: $weight-medium;
  letter-spacing: 0.1rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 1.5em 1em;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  &.active {
    background-color: #ffffff;
    color: $main-color;
  }
}

.reference-info-section__image {
  display: block;
  padding: 40px 0px;
  display: none;

  &.active {
    display: block;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @include respond-to('large') {
    @include respond-to('landscape') {
      padding: 40px 20px;
    }
  }
}

.reference-info-section__info {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  div {
    font-weight: $weight-light;
    flex-basis: 50%;
    text-align: center;
    line-height: 1.4rem;

    img {
      display: block;
      height: 50px;
      margin: 0 auto;
    }
  }
}
