.contatti {
  background-image: url('../img/contatti-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  //@include full-height;

  @include respond-to('medium') {
    background-image: url('../img/contatti.jpg');
  }

  @include respond-to('huge') {
    background-image: url('../img/contatti-huge.jpg');
  }

  main {
    @include standard-dimensions;

    //padding: 60px 30px;

    h1,
    .subtitle,
    .page-number {
      // Status iniziale animazione
      visibility: hidden;
      will-change: transform;
    }

    @include respond-to('medium') {
      //@include main-full-height;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 100px;
      //padding: 70px 200px;
      //max-width: 1900px;
      //margin: 0 auto;

      padding-left: $medium-horizontal-padding + 100px;
      padding-right: $medium-horizontal-padding + 100px;
    }

    @include respond-to('large') {
      //padding-top: 0;
      justify-content: center;
    }

    @include respond-to('extra-large') {
      padding-left: $extra-large-horizontal-padding + 100px;
      padding-right: $extra-large-horizontal-padding + 100px;
    }
  }

  .standard-title {
    margin-bottom: 50px;

    @include respond-to('medium') {
      margin-bottom: 60px;
    }
  }
}

.contact-info {
  font-size: 1.2rem;

  // Status iniziale animazione
  visibility: hidden;
  will-change: transform;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  strong {
    display: block;
    margin-bottom: 30px;
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 300;
    margin-bottom: 10px;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    img {
      height: 20px;
      margin-right: 10px;
    }
  }

  .social-container {
    img {
      height: 35px;
    }
  }

  @include respond-to('medium') {
    font-size: 1rem;

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    .social-container {
      img {
        height: 25px;
      }
    }
  }
}
