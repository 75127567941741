.electrade {
  background-color: #a3c110;
  background-image: url('../img/electrade.jpg');
  background-size: cover;
  background-position: center center;

  @include respond-to('large') {
    @include full-height;
  }

  @include respond-to('huge') {
    background-image: url('../img/electrade@2x.jpg');
  }

  main {
    @include standard-dimensions;

    //padding: 60px 30px;

    @include respond-to('large') {
      @include respond-to('landscape') {
        @include main-full-height;

        //padding: 20px 100px;
        //max-width: 1900px;
        //margin: 0 auto;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @include respond-to('large') {
      @include respond-to('portrait') {
        padding-left: 150px;
        padding-right: 150px;
      }
    }

    h1 {
      margin-bottom: 10px;
    }

    p {
      line-height: 1.8rem;
      margin-bottom: 50px;
    }

    .standard-button {
      margin: 0 auto 40px;

      @include respond-to('large') {
        @include respond-to('landscape') {
          margin: 0 0 20px auto;
        }
      }
    }
  }
}

.electrade-description {
  @include respond-to('large') {
    @include respond-to('landscape') {
      text-align: right;
      grid-column: 2 / 3;
      justify-self: right;
      max-width: 600px;
    }
  }

  @include respond-to('huge') {
    align-self: center;
  }
}

.electrade-data {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 25px;

  @include respond-to('large') {
    @include respond-to('landscape') {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: block;
      justify-self: start;
      align-self: center;
    }
  }
}

.electrade-data__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: $weight-medium;
  font-size: 0.8rem;
  text-align: center;

  &:nth-child(1) {
    grid-column: 1 / 3;
  }
  &:nth-child(2) {
    grid-column: 3 / 5;
  }
  &:nth-child(3) {
    grid-column: 5 / 7;
  }
  &:nth-child(4) {
    grid-column: 2 / 4;
  }
  &:nth-child(5) {
    grid-column: 4 / 6;
  }

  @include respond-to('large') {
    @include respond-to('landscape') {
      flex-direction: row;
      margin-bottom: 20px;
    }
  }
}

.electrade-data__info__circle {
  font-weight: $weight-light;
  font-size: 1.3rem;
  height: 70px;
  width: 70px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-bottom: 10px;
  position: relative;

  @include respond-to('large') {
    @include respond-to('landscape') {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  span {
    position: absolute;
    top: calc(50% + 12px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.6rem;
  }
}

.electrade-logo {
  display: block;
  height: 60px;
  margin-bottom: 20px;

  img {
    height: 100%;
  }
}

.electrade-button-container {
  @include respond-to('medium') {
    display: flex;
    flex-direction: row;
  }
}
