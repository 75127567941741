header {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 11;

  @include respond-to('medium') {
    padding: 50px 80px 10px;
  }
}

.fixed-header-desktop header {
  @include respond-to('medium') {
    position: fixed;
    top: 0;
    width: 100vw;
  }
}

.logo {
  width: 120px;

  @include respond-to('medium') {
    width: 150px;
  }
}

.header-menu {
  display: flex;
  flex-direction: row;
}

.header-menu__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;

  opacity: 0;
  pointer-events: none;
  transform: translateY(-100%);
  transition: all 300ms ease 200ms;

  &.active {
    opacity: 1;
    pointer-events: initial;
    transform: translateY(0);
  }

  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    padding: 4px 7px;
    transition: color 150ms linear;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }

    &:not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    @include respond-to('medium') {
      padding: 4px 10px;
    }
  }

  @include respond-to('medium') {
    margin-right: 50px;
  }
}

.header-menu__links__policies {
  display: none;

  @include respond-to('medium') {
    display: block;
    position: static;
    margin-right: 50px;
    transform: none;
  }
}

.header-menu__links__languages {
  a {
    &.active {
      color: rgba(255, 255, 255, 1);
      pointer-events: none;
      display: none;

      @include respond-to('medium') {
        display: initial;
      }
    }
  }
}

.hamburger {
  height: 23px; // MAGIC NUMBER
  width: 30px;
  position: relative;
  cursor: pointer;

  // Aumenta area click
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  .line1 {
    display: block;
    content: '';
    position: absolute;
    width: 80%;
    height: 1.5px;
    background-color: #ffffff;
    top: 0;
    transform-origin: center left;
    transition: all 300ms ease;
  }

  .line2 {
    display: block;
    content: '';
    position: absolute;
    width: 60%;
    height: 1.5px;
    background-color: #ffffff;
    top: calc(50% - 0.75px);
    transform-origin: center left;
    transition: all 300ms ease;
  }

  .line3 {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 1.5px;
    background-color: #ffffff;
    bottom: 0;
    transform-origin: center left;
    transition: all 300ms ease;
  }

  &:not(.opened):hover {
    .line1 {
      width: 100%;
    }

    .line2 {
      width: 66%;
    }

    .line3 {
      width: 33%;
    }
  }

  &.opened {
    .line1 {
      width: 100%;
      transform: rotate(45deg);
    }

    .line2 {
      opacity: 0;
    }

    .line3 {
      transform: rotate(-45deg);
    }
  }
}
