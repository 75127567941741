.privacy {
  main {
    @include standard-dimensions;

    p {
      text-align: justify;
    }

    a {
      color: #ffffff;
    }

    ul {
      list-style-type: disc;
      line-height: 1.5rem;
      margin-left: 20px;

      & > li > ul {
        list-style-type: circle;
      }
    }
  }
}

.privacy-subtitle {
  margin-left: 20px;
}
