// Preloader
.preloader-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $main-color;
  font-size: 20px;
  display: block;
  transform: translateY(-100%);
  pointer-events: none;
  transition: transform 350ms ease;

  .cssload-loader {
    opacity: 0;
  }

  &.active {
    transform: none;

    .cssload-loader {
      opacity: 1;
    }
  }
}

.cssload-loader {
  position: fixed;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
  width: 72px;
  height: 72px;
  border-radius: 50%;
  perspective: 900px;
  transition: opacity 250ms linear;
}

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
}

.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.6s linear infinite;
  border-bottom: 3px solid rgb(255, 255, 255);
}

.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.6s linear infinite;
  border-right: 3px solid rgb(255, 255, 255);
}

.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.6s linear infinite;
  border-top: 3px solid rgb(255, 255, 255);
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

body {
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: $weight-regular;
  color: $text-color-base;
  background-color: $main-color;

  overflow-x: hidden;

  // min height 100vh
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);

  &.fixed-header-desktop {
    @include respond-to('medium') {
      padding-top: 120px; /* Fallback for browsers that do not support Custom Properties 120: MAGIC NUMBER*/
      padding-top: var(--header-height, 120px);
    }
  }
}

h1 {
  font-size: 3rem;
  font-weight: $weight-extra-bold;

  @include respond-to('medium') {
    font-size: 5rem;
  }
}

h2 {
  font-size: 1.2rem;
  font-weight: $weight-medium;
}

p {
  line-height: 1.5rem;
}

strong {
  font-weight: $weight-bold;
}

hr {
  border: none;
  height: 1px;
  background-color: #ffffff;
}

.standard-title {
  position: relative;
  margin-bottom: 0px;
}

.subtitle {
  margin-bottom: 25px;
}

.page-number {
  position: absolute;
  left: 0;
  bottom: -50%;
  font-weight: $weight-extra-bold;
  font-size: 10rem;
  opacity: 0.15;

  @include respond-to('medium') {
    font-size: 17rem;
    bottom: initial;
    top: calc(30% - 8.5rem);
    left: -10%;
  }
}

/* Helper classes */
@include respond-to('max-small') {
  .medium-only {
    display: none !important;
  }
}

@include respond-to('max-medium') {
  .tablet-only {
    display: none !important;
  }
}

@include respond-to('max-large') {
  .desktop-only {
    display: none !important;
  }
}

@include respond-to('extra-large') {
  .tablet-only {
    display: none !important;
  }
}

@include respond-to('medium') {
  .mobile-only {
    display: none !important;
  }
}

@include respond-to('max-small') {
  .hide-mobile {
    display: none !important;
  }
}

@include respond-to('extra-large') {
  .hide-desktop {
    display: none !important;
  }
}

@include respond-to('max-small') {
  .tablet-above {
    display: none !important;
  }
}
