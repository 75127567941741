.pide {
  background-image: url('../img/pide-mobile.jpg');
  background-position: center center;
  background-size: cover;

  @include respond-to('medium') {
    background-image: url('../img/pide.jpg');
  }

  @include respond-to('huge') {
    background-image: url('../img/pide@2x.jpg');
  }

  main {
    @include standard-dimensions;
    //padding: 60px 30px;

    h1,
    .subtitle,
    .page-number {
      // Status iniziale animazione
      visibility: hidden;
      will-change: transform;
    }

    @include respond-to('medium') {
      //padding: 100px 150px;
      //max-width: 1900px;
      //margin: 0 auto;

      .standard-title {
        margin-top: 50px;
        margin-left: 100px;
      }
    }

    @include respond-to('huge') {
      //padding: 200px 150px;
    }

    p {
      max-width: 420px;
      // Status iniziale animazione
      visibility: hidden;
      will-change: transform;

      @include respond-to('medium') {
        margin-left: 100px;
      }
    }
  }
}

.pide-text {
  display: block;
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-weight: $weight-light;
  max-width: 450px;
  margin-top: 50px;
  margin-bottom: 40px;
  // Status iniziale animazione
  visibility: hidden;
  will-change: transform;

  @include respond-to('medium') {
    margin-top: 70px;
    margin-left: 100px;
  }
}
