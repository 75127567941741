.home {
  @include full-height;
  overflow: hidden;

  background-image: url('../img/home-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  /*
  .logo-link {
    margin: 0 auto;
  }
  */

  @include respond-to('max-medium') {
    @include respond-to('landscape') {
      height: 200vh;
      overflow: initial;
    }
  }

  @include respond-to('large') {
    @include respond-to('landscape') {
      background-image: url('../img/home.jpg');
      background-size: cover;
      background-position: 50% 75%;

      .logo-link {
        margin: initial;
      }
    }
  }

  @include respond-to('huge') {
    background-image: url('../img/home-huge.jpg');
  }
}

#circle-anim-1,
#circle-anim-2 {
  position: fixed;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 160%;
  //max-width: 700px;
  height: auto;
  z-index: $z-index-back-anims;

  @include respond-to('max-medium') {
    @include respond-to('landscape') {
      position: absolute;
      width: 100%;
      top: 140%;
    }
  }

  @include respond-to('large') {
    @include respond-to('landscape') {
      max-width: initial;
      left: 0;
      top: 56%;
      transform: translate(17%, -50%);
      width: 85%;
      height: 85%;
    }
  }
}

#circle-anim-1 {
  filter: blur(15px);
}

.home-title {
  font-weight: $weight-extra-bold;
  font-size: 4rem;
  position: fixed;
  top: 16vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  visibility: hidden;

  @include respond-to('max-medium') {
    @include respond-to('landscape') {
      position: absolute;
      top: 50vh;
    }
  }

  @include respond-to('large') {
    @include respond-to('landscape') {
      position: static;
      text-align: left;
      transform: none;
      /*
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
    */
    }
  }

  @include respond-to('extra-large') {
    /*left: 150px;*/
  }
}

.home-text {
  position: fixed;
  top: 180px;
  left: 50%;
  max-width: 300px;
  transform: translateX(-50%);
  text-align: center;
  visibility: hidden;
  display: none;
  font-size: 1rem;
  font-weight: $weight-regular;
  line-height: 1.5rem;

  @include respond-to('large') {
    @include respond-to('landscape') {
      position: static;
      display: block;
      text-align: left;
      transform: none;
      margin-top: 10px;
      /*
    top: calc(50% + 7rem);
    left: 50px;
    transform: translateY(-50%);
    */
    }
  }
  @include respond-to('extra-large') {
    /*left: 150px;
    */
  }
}

.home-text-container {
  @include respond-to('large') {
    @include respond-to('landscape') {
      position: fixed;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
    }
  }

  @include respond-to('extra-large') {
    left: 150px;
  }
}
