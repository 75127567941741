.certificazioni {
  main {
    @include standard-dimensions;

    @include respond-to('medium') {
      .standard-title {
        margin-top: 50px;
        margin-left: 100px;
      }
    }

    ul {
      margin-top: 50px;
      max-width: 420px;
      list-style-type: none;

      @include respond-to('medium') {
        margin-left: 100px;
      }

      li {
        padding: 10px;
        padding-left: 20px;
        position: relative;

        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 50%;
          right: 100%;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          border: 1.5px solid #ffffff;
          transform: translateY(-50%);
          transition: all 150ms linear;
        }

        a {
          color: #ffffff;
        }
      }
    }
  }
}
